import React from 'react';
import { graphql } from 'gatsby';

import CollectionList from '../components/collections/CollectionList/CollectionList';
import Layout from '../components/furniture/Layout/Layout';
import generateTitle from '../utils/generateTitle';

const FeaturesPage = ({ data, location }) => {
  const {
    features: { edges: features },
  } = data;

  const pageTitle = generateTitle('Features List', 'Features');

  return (
    <Layout
      location={location}
      pageTitle={pageTitle}
    >
      <CollectionList collections={features} section="features" title="All Features" />
    </Layout>
  );
};

export const pageQuery = graphql`
    query featuresQuery {
        features: allAirtable(
            sort: { fields: [data___date], order: DESC }
            filter: { table: { eq: "features" } }
        ) {
            edges {
                node {
                    data {
                        title
                        slug
                        image {
                            localFiles {
                                childImageSharp {
                                    fluid(maxWidth: 600, quality: 40) {
                                        ...GatsbyImageSharpFluid_noBase64
                                    }
                                }
                            }
                        }
                        type
                        tags {
                            data {
                                title
                                slug
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default FeaturesPage;
